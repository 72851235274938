import React from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { StaticImage } from 'gatsby-plugin-image';
import styled from 'styled-components';

import Card from '@components/card/card.component';
import Layout from '@components/layout/layout.component';
import PageContainer from '@components/page-container/page-container.component';

const Section = styled.section`
  &:not(:first-of-type) {
    margin-top: 42px;
  }

  h3 {
    font-size: 2rem;
    margin-bottom: 15px;
  }
`;

const ImgRow = styled(Row)`
  &:not(:last-of-type) {
    margin-bottom: 24px;
  }

  .img {
    border-radius: 5px;
    height: 200px;
  }
`;

const TextSectionContainer = styled.div`
  margin-right: 74px;
`;

const AboutUsPage = ({ location }) => {
  return (
    <Layout title='Sobre nós' location={location}>
      <StaticImage
        loading='eager'
        src='../images/bg03.jpeg'
        alt=''
        layout='fullWidth'
        className='header-media'
      />

      <PageContainer>
        <h2 className='subtitle' style={{ margin: '32px 0' }}>Sobre nós</h2>

        <Card padding='35px 76px'>
          <Row>
            <Col md={8} sm={12}>
              <TextSectionContainer className='rst-margin-mobile'>
                <Section>
                  <h3>Missão!</h3>
                  <p>Projetar, produzir e comercializar equipamentos para transporte hospitalar (resgate) e ressonância
                    magnética. Tendo em vista: segurança, conforto, qualidade e praticidade</p>
                </Section>

                <Section>
                  <h3>Visão!</h3>
                  <p>Ser reconhecida como a melhor opção do mercado. Uma empresa atuante de forma abrangente no segmento
                    de equipamentos hospitalares. Através de um portfólio de produtos com qualidade, dentro dos padrões
                    requeridos, com características e propostas únicas</p>
                </Section>

                <Section>
                  <h3>Valores!</h3>
                  <p>Qualidade e melhoria continua dos produtos e serviços para satisfação dos clientes produção
                    sustentável nossos produtos são 100% reutilizáveis. <span style={{ color: 'green' }} role='img'
                                                                              aria-label='ícone de reciclagem'>♻️</span>
                    <br /><br />Respeito à vida;<br />Ética e transparência; Empreendedorismo e inovação.</p>
                </Section>

                <Section>
                  <h3>Valores corporativos!</h3>
                  <p>
                    Segurança total no ambiente de trabalho;
                    <br />
                    <br />
                    Colaboradores comprometidos e realizados;
                    <br />
                    <br />
                    Crescimento e rentabilidade.
                  </p>
                </Section>
              </TextSectionContainer>
            </Col>
            <Col md className='hide-mobile'>
              <ImgRow>
                <StaticImage
                  src='../images/bg00.jpeg'
                  alt=''
                  layout='constrained'
                  className='img'
                />
              </ImgRow>
              <ImgRow>
                <StaticImage
                  src='../images/bg01.jpeg'
                  alt=''
                  layout='constrained'
                  className='img'
                />
              </ImgRow>
              <ImgRow>
                <StaticImage
                  src='../images/bg02.jpeg'
                  alt=''
                  layout='constrained'
                  className='img'
                />
              </ImgRow>
            </Col>
          </Row>
        </Card>
      </PageContainer>
    </Layout>
  );
};

export default AboutUsPage;
